import {VizzionApiService} from "./VizzionApiService";

export class ClockworkService extends VizzionApiService {
  _endpoint = "/worklog";

  constructor() {
    super({});
  }

  async create(data) {
    return this.makeHttpRequest({
      url: this._endpoint,
      method: 'POST',
      data
    });
  }

  async getById(id) {
    return this.makeHttpRequest({
        url: `${this._endpoint}/${id}`,
        method: 'GET'
    })
  }

  async getTotalHoursByUserId(id) {
    return this.makeHttpRequest({
        url: `${this._endpoint}/total/${id}`,
        method: 'GET'
    })
  }

  async getTotalHoursByUserIdAndMonth(id, month) {
    return this.makeHttpRequest({
        url: `${this._endpoint}/total/${id}/${month}`,
        method: 'GET'
    })
  }
  async getTotalHoursByUserIdAndMonthAndStatus(id, month, status) {
    return this.makeHttpRequest({
        url: `${this._endpoint}/total/${id}/${month}/${status}`,
        method: 'GET'
    })
  }

  async getPaged(filter) {
    return this.makeHttpRequest({
      url: this._endpoint,
      method: 'GET',
      params: filter,
      paramsSerializer: params => {
        return Object.entries(params)
          .map(([key, value]) => `${key}=${value}`)
          .join('&');
      }
    });
  }
  async getGrouped(filter) {
    return this.makeHttpRequest({
      url: `${this._endpoint}/grouped`,
      method: 'GET',
      params: filter,
      paramsSerializer: params => {
        return Object.entries(params)
          .map(([key, value]) => `${key}=${value}`)
          .join('&');
      }
    });
  }

  async approve(id) {
    return this.makeHttpRequest({
        url: `${this._endpoint}/approve/${id}`,
        method: 'PUT'
    });
  }

  async reject(id) {
    return this.makeHttpRequest({
        url: `${this._endpoint}/reject/${id}`,
        method: 'PUT'
    });
  }

  async delete(id) {
    return this.makeHttpRequest({
        url: `${this._endpoint}/${id}`,
        method: 'DELETE'
    });
  }

  async update(id, data) {
    return this.makeHttpRequest({
        url: `${this._endpoint}/${id}`,
        method: 'PUT',
        data
    });
  }

  async export(filter) {
    return this.download({
        url: `${this._endpoint}/export`,
        method: 'GET',
        params: filter,
        paramsSerializer: params => {
          return Object.entries(params)
              .map(([key, value]) => `${key}=${value}`)
              .join('&');
        }
    })
  }
}
